<template>
  <!-- Navbar start -->
  <nav
    :class="{
      'bg-black shadow-md transition-opacity bg-opacity-80':
        isScrolled || showMenu,
      'bg-transparent': !isScrolled && !showMenu,
    }"
    class="w-full fixed p-2 px-5 md:px-5 lg:px-8 flex justify-between z-40 transition-all"
  >
    <a @click="showMenu = false" href="/">
      <div class="h-full flex flex-row justify-between items-center">
        <img
          v-motion-pop-visible
          src="../assets/romieinc-nav-logo.png"
          class="w-full h-[45px] md:h-[50px] mt-1"
          alt="logo"
        />
      </div>
    </a>

    <button role="menubar" class="lg:hidden" @click="toggleMenu()">
      <!-- <i class="fa-solid fa-bars text-2xl text-white"></i> -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="white"
        class="w-9 h-8"
      >
        <path
          fill-rule="evenodd"
          d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z"
          clip-rule="evenodd"
        />
      </svg>
    </button>

    <div
      :class="{
        'bg-opacity-80 bg-black lg:bg-transparent': isScrolled || showMenu,
        'bg-opacity-80': !isScrolled && !showMenu,
        hidden: !showMenu && isMobile(),
        flex: showMenu || !isMobile(),
      }"
      class="absolute left-0 right-0 mt-14 md:mt-14 shadow lg:flex flex-col gap-4 justify-end items-start lg:item-center lg:mt-2 p-2 lg:flex-row lg:static lg:shadow-none lg:translate-y-0 transition-all"
      role="menu"
      :aria-expanded="showMenu.toString()"
    >
      <div
        v-motion-pop-visible
        class="flex flex-col gap-[20px] lg:mb-0 lg:flex-row justify-evenly uppercase whitespace-nowrap text-lg text-[#FF3D3D] font-['Russo_One'] text-[25px] font-semibold"
      >
        <a
          href="/#leaderboard"
          @click="showMenu = false"
          role="menuitem"
          class="px-4 py-1.5 routerBtn transition-colors rounded-2xl shadow-lg"
        >
          Leaderboard
        </a>
        <a
          href="/#challenges"
          @click="showMenu = false"
          role="menuitem"
          class="px-4 py-1.5 routerBtn transition-colors rounded-2xl shadow-lg"
        >
          Challenges
        </a>
        <a
          href="/#socials"
          @click="showMenu = false"
          role="menuitem"
          class="px-4 py-1.5 routerBtn transition-colors rounded-2xl shadow-lg"
        >
          Socials
        </a>
        <router-link
          to="/balance-guess/list"
          @click="showMenu = false"
          role="menuitem"
          class="px-4 py-1.5 routerBtn transition-colors rounded-2xl shadow-lg"
        >
          GUESS
        </router-link>
        <router-link
          v-if="this.$store.state.isLoggedIn"
          to="/profile"
          @click="showMenu = false"
          role="menuitem"
          class="px-4 py-1.5 routerBtn transition-colors rounded-2xl shadow-lg"
        >
          Profile
        </router-link>
        <a
          v-else
          @click="loginDiscord"
          role="menuitem"
          class="px-4 py-1.5 routerBtn transition-colors rounded-2xl shadow-lg"
        >
          Login
        </a>
      </div>
    </div>
  </nav>
  <!-- Navbar end -->
</template>

<script>
export default {
  emits: ["logout"],
  data() {
    return {
      isScrolled: false,
      showMenu: false,
      // isNotLoggedIn: true,
    };
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 0;

      if (this.showMenu && window.innerWidth < 768) {
        this.isScrolled = true;
      }
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
      if (this.showMenu && window.innerWidth < 768) {
        this.isScrolled = true;
      } else if (!this.showMenu && window.scrollY === 0) {
        this.isScrolled = false;
      }
    },
    isMobile() {
      return window.innerWidth < 1024; // Adjust based on your 'lg' breakpoint in Tailwind CSS config
    },
    async loginDiscord() {
      this.showMenu = false;
      window.location.href = `${process.env.VUE_APP_BASE_API}/api/auth/discord`;
    },
    handleLogout() {
      this.$emit("logout");
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
      // this.isNotLoggedIn = this.$store.state.isLoggedIn;
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped></style>
