<template>
  <section class="h-full payout-guess-bg">
    <div class="pt-40 md:pt-60">
      <div
        id="payout-guess-header"
        class="h-32 md:h-[205px] w-full relative flex items-center justify-center"
      >
        <img
          src="../assets/RomieInc-GTB-LOGO.png"
          class="md:h-[194px] w-auto h-32 absolute -top-24 md:-top-32"
        />
        <img
          src="../assets/guess-the-payout.png"
          class="h-[72px] w-auto z-[12]"
        />
        <img
          src="../assets/redblood-bg-top.png"
          class="h-20 md:h-[107px] w-auto absolute top-4 md:top-8 z-10"
        />
        <img
          src="../assets/redblood-bg-bottom.png"
          class="h-20 md:h-[107px] w-auto absolute top-12 md:top-20 z-[11]"
        />
      </div>
      <div
        class="mt-10 md:mt-20 flex flex-col gap-10 md:gap-[60px] justify-center items-center px-4 xl:px-auto max-w-[1200px] mx-auto"
      >
        <div>
          <h3
            class="text-[#FFD600] font-['Roboto'] text-[22px] mb-4 tracking-wider"
          >
            PLEASE ENTER GUESS FOR GTB AND GTP BEFORE TIMER EXPIRES
          </h3>
          <div v-if="balanceGuessingData.status != 'active'">
            <h2
              v-if="!loading && !timer.isRunning"
              class="text-white font-['Russo_One'] text-lg md:text-[30px] text-center tracking-widest"
            >
              Timer Ended
            </h2>
            <h2
              v-else
              class="text-white font-['Russo_One'] text-lg md:text-[30px] text-center tracking-widest"
            >
              {{ formatTimer(timer?.minutes) }}M&nbsp;
              {{ formatTimer(timer?.seconds) }}S
            </h2>
          </div>
          <div v-else>
            <h2
              class="text-white font-['Russo_One'] text-lg md:text-[30px] text-center tracking-widest"
            >
              Starting Soon...
            </h2>
          </div>
        </div>
        <div
          class="w-full p-4 md:pt-5 md:pb-16 md:pl-9 bg-[rgb(18,16,16)] font-['Roboto,_sans-serif'] font-bold text-lg md:text-xl flex flex-col gap-y-5"
        >
          <div class="text-[#FFD600] text-lg md:text-xl">
            TODAY:
            {{
              balanceGuessingData && !loading
                ? formatDate(balanceGuessingData.createdAt)
                : ""
            }}
          </div>
          <div class="flex justify-between md:justify-start md:gap-[280px]">
            <div class="text-[#FFD600] text-lg md:text-xl">
              STARTING BALANCE:
            </div>
            <div class="text-white text-lg md:text-xl">
              {{
                balanceGuessingData && !loading
                  ? "$" + balanceGuessingData.startingBalance
                  : ""
              }}
            </div>
          </div>
          <div class="flex justify-between md:justify-start md:gap-40">
            <div class="text-[#FFD600] text-lg md:text-xl">
              TOTAL BONUS BUYS FOR TODAY:
            </div>
            <div class="text-white text-lg md:text-xl">
              {{
                balanceGuessingData && !loading ? ongoingPayoutData.length : ""
              }}
            </div>
          </div>
        </div>
        <div class="bg-[rgb(18,16,16)] p-4 md:px-9 md:pt-8 md:pb-[29px] w-full">
          <Form @submit="submitGuess" @invalid-submit="onInvalidSubmit">
            <table class="w-full">
              <thead class="mb-5">
                <tr class="text-white text-[15px]">
                  <th class="w-1/4 text-inherit text-left pr-2">SLOT NAME</th>
                  <th class="w-1/4 text-inherit text-left pr-2">STATUS</th>
                  <th class="w-1/4 text-inherit text-left pr-2">
                    BONUS BUY COST
                  </th>
                  <th class="w-1/4 text-inherit text-left">GUESS THE PAYOUT</th>
                </tr>
              </thead>
              <tbody v-if="balanceGuessingData.status != 'active'">
                <tr
                  v-for="(data, index) in ongoingPayoutData"
                  class="text-white mt-4 text-[15px]"
                  :key="index"
                >
                  <td class="uppercase py-2 pr-2 text-inherit">
                    {{ data.slotTitle }}
                  </td>
                  <td class="uppercase text-inherit">{{ data.status }}</td>
                  <td class="text-inherit">${{ data.bonusBuyCost }}</td>
                  <td>
                    <Field
                      v-model="guessThePayoutData[index].gtpUserGuess"
                      type="number"
                      :name="'gtp' + index"
                      :rules="isValidGuess"
                      class="border shadow-md w-full px-1"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              v-if="balanceGuessingData.status != 'active'"
              class="flex justify-end mt-10 md:mt-[110px]"
            >
              <button
                type="submit"
                class="bg-[#3A3333] py-2 px-2 text-white text-lg md:text-xl font-bold"
                style="font-family: Roboto, sans-serif"
                v-if="!isGuessing"
                :disabled="isGuessing"
              >
                SUBMIT ALL MY ENTRIES
              </button>
              <beat-loader
                v-else
                :loading="isGuessing"
                color="white"
                size="20px"
              ></beat-loader>
            </div>
          </Form>
        </div>
        <div class="self-start flex justify-start items-start gap-2">
          <div class="bg-[#3A3333] mb-10 py-3 px-4 gap-2">
            <router-link
              to="/balance-guess/list"
              class="text-lg md:text-xl font-bold text-white"
              >GO BACK</router-link
            >
          </div>
          <div class="bg-[#3A3333] mb-10 py-3 px-4 gap-2">
            <router-link
              :to="`/balance-guess/${this.balanceGuessingData._id}/live`"
              class="text-lg md:text-xl font-bold text-white"
              >GO TO PARENT - GTB</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { defineComponent } from "vue";
import { Field, Form, ErrorMessage } from "vee-validate";
// import * as Yup from "yup";
import { useTimer } from "vue-timer-hook";
import moment from "moment-timezone";
import axios from "axios";
import Swal from "sweetalert2";
import BeatLoader from "vue-spinner/src/BeatLoader.vue";

export default defineComponent({
  components: {
    Field,
    Form,
    // eslint-disable-next-line vue/no-unused-components
    ErrorMessage,
    BeatLoader,
  },
  data() {
    return {
      loading: false,
      loadingGuessData: false,
      isGuessing: false,
      balanceGuessingData: [],
      guessThePayoutData: [],
      accessToken: localStorage.getItem("romieinc-access-token"),
      ongoingPayoutData: [],
      userGuess: [],
      schema: null,
      timer: "",
    };
  },
  methods: {
    async getGtbData() {
      this.loading = true;
      await axios
        .get(
          `${process.env.VUE_APP_BASE_API}/api/guess-the-balance/details/${this.$route.params.gtbId}`,
          {
            headers: {
              "x-api-key": process.env.VUE_APP_X_API_KEY,
            },
          }
        )
        .then((response) => {
          this.balanceGuessingData = response.data.gtb;
          if (response.data.gtb.timerTimeStarted) {
            this.timer = useTimer(
              moment(response.data.gtb.timerTimeStarted)
                .utc()
                .add(response.data.gtb.timerDuration ?? 10, "minutes")
            );
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
        });
    },
    async getOngoingPayoutData() {
      await axios
        .get(
          `${process.env.VUE_APP_BASE_API}/api/gtp/gtp-status?gtbId=${this.$route.params.gtbId}&status=ongoing`,
          {
            headers: {
              // Authorization: `Bearer ${this.accessToken}`,
              "x-api-key": process.env.VUE_APP_X_API_KEY,
            },
          }
        )
        .then((response) => {
          this.ongoingPayoutData = response.data.statusSlot;
          this.getGuessData();

          response.data.statusSlot.forEach((item) => {
            this.guessThePayoutData.push({
              gtp: item._id,
              gtpUserGuess: "",
            });
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getGuessData() {
      this.loadingGuessData = true;
      await axios
        .get(
          `${process.env.VUE_APP_BASE_API}/api/gtp/gtb-guesses?gtbId=${this.$route.params.gtbId}`,
          {
            headers: {
              Authorization: `Bearer ${this.accessToken}`,
              // "x-api-key": process.env.VUE_APP_X_API_KEY,
            },
          }
        )
        .then((response) => {
          this.userGuess = response.data.userGuesses;
          let guessData = [];

          this.ongoingPayoutData.forEach((item, index) => {
            if (
              this.userGuess.length > 0 &&
              index < this.userGuess.length &&
              this.userGuess[index]?.gtp == item._id
            ) {
              guessData.push({
                gtp: item._id,
                gtpUserGuess: this.userGuess[index].gtpUserGuess,
              });
            } else {
              guessData.push({
                gtp: item._id,
                gtpUserGuess: "",
              });
            }
          });

          this.guessThePayoutData = guessData;
          this.loadingGuessData = false;
        })
        .catch((error) => {
          console.error(error);
          this.loadingGuessData = false;
        });
    },
    async submitGuess() {
      try {
        if (!this.$store.state.isLoggedIn) {
          return this.$router.push({
            path: "/login",
          });
        }

        this.isGuessing = true;

        if (this.balanceGuessingData.status !== "ongoing") {
          throw {
            name: "ValidationError",
            message: "We are not accepting guess/es as of the moment",
          };
        }

        if (
          this.userGuess.length > 0 &&
          this.ongoingPayoutData.length == this.userGuess.length
        ) {
          throw {
            name: "ValidationError",
            message: "You have reached the maximum number of guesses",
          };
        }

        if (!this.timer.isRunning) {
          throw { name: "TimerError", message: "Timer has expired!" };
        }

        const guessThePayout = await axios.post(
          `${process.env.VUE_APP_BASE_API}/api/gtp/add-guess`,
          {
            gtbId: this.balanceGuessingData._id,
            guessBody: this.guessThePayoutData,
          },
          {
            headers: {
              Authorization: `Bearer ${this.accessToken}`,
            },
          }
        );

        if (guessThePayout.status !== 200) {
          throw {
            name: "ValidationError",
            message: "Please enter valid guesses!",
          };
        }

        Swal.fire({
          title: "Success!",
          text: "Successfully submitted guess!",
          icon: "success",
          confirmButtonText: "Okay",
          color: "#a5dc86",
          confirmButtonColor: "#a5dc86",
          background: "#121212",
          timer: 3000,
        });

        this.getGuessData();
        this.isGuessing = false;
      } catch (error) {
        if (error?.response?.status === 401) {
          localStorage.removeItem("vuex");
          localStorage.removeItem("romieinc-access-token");
          window.location.reload();
        }
        this.isGuessing = false;
        console.log(error);
        Swal.fire({
          title: "Error!",
          text: error?.response?.data?.message ?? error.message,
          icon: "error",
          confirmButtonText: "Okay",
          color: "#FF3D3D",
          confirmButtonColor: "#FF3D3D",
          background: "#121212",
          timer: 3000,
        });
      }
    },
    onInvalidSubmit({ results }) {
      const isNotMatched =
        Object.keys(results).filter((result) => !result.valid).length > 0;

      if (isNotMatched) {
        Swal.fire({
          title: "Error!",
          text: "Please input valid guesses!",
          icon: "error",
          confirmButtonText: "Okay",
          color: "#FF3D3D",
          confirmButtonColor: "#FF3D3D",
          background: "#121212",
          timer: 3000,
        });
      }
    },
    isValidGuess(value) {
      return value && !Number.isNaN(value);
    },
    formatTimer(seconds) {
      if (seconds < 10) {
        return "0" + seconds;
      }

      return seconds;
    },
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },
  },
  mounted() {
    this.getGtbData();
    this.getOngoingPayoutData();
  },
  watch: {
    timer(timer) {
      if (timer.isExpired.value) {
        this.timer.pause();
        console.warn("Timer is expired");
      }
    },
  },
});
</script>
<style>
.payout-guess-bg {
  background-image: url("../assets/red-black-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#payout-guess-header {
  background-image: url("../assets/red-clouds-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

input + span {
  display: block;
}
</style>
