<template>
  <section class="h-full payout-guess-bg">
    <div class="pt-40 md:pt-60">
      <div
        id="payout-guess-header"
        class="h-32 md:h-[205px] w-full relative flex items-center justify-center"
      >
        <img
          src="../assets/RomieInc-GTB-LOGO.png"
          class="md:h-[194px] w-auto h-32 absolute -top-24 md:-top-32"
        />
        <img
          src="../assets/guess-the-balance.png"
          class="h-[72px] w-auto z-[12]"
        />
        <img
          src="../assets/redblood-bg-top.png"
          class="h-20 md:h-[107px] w-auto absolute top-4 md:top-8 z-10"
        />
        <img
          src="../assets/redblood-bg-bottom.png"
          class="h-20 md:h-[107px] w-auto absolute top-12 md:top-20 z-[11]"
        />
      </div>
      <div
        class="mt-10 md:mt-20 flex flex-col gap-10 md:gap-[60px] justify-center items-center px-5 xl:px-0 max-w-[1200px] mx-auto"
      >
        <div>
          <h3
            class="text-[#FFD600] font-['Roboto'] text-[22px] mb-4 tracking-wider"
          >
            PLEASE ENTER GUESS FOR GTB AND GTP BEFORE TIMER EXPIRES
          </h3>
          <div v-if="balanceGuessingData.status != 'active'">
            <h2
              v-if="!timer.isRunning && !loading"
              class="text-white font-['Russo_One'] text-lg md:text-[30px] text-center tracking-widest"
            >
              Timer Ended
            </h2>
            <h2
              v-else
              class="text-white font-['Russo_One'] text-lg md:text-[30px] text-center tracking-widest"
            >
              {{ formatTimer(timer.minutes) }}M&nbsp;
              {{ formatTimer(timer.seconds) }}S
            </h2>
          </div>
          <div v-else>
            <h2
              class="text-white font-['Russo_One'] text-lg md:text-[30px] text-center tracking-widest"
            >
              Starting Soon...
            </h2>
          </div>
        </div>
        <div
          class="w-full py-5 pl-9 pr-5 lg:pr-[148px] bg-[rgb(18,16,16)] font-['Roboto,_sans-serif'] font-bold text-xl flex flex-col gap-y-5"
        >
          <div class="text-[#FFD600] md:text-xl text-lg">
            TODAY:
            {{
              balanceGuessingData && !loading
                ? formatDate(balanceGuessingData.createdAt)
                : ""
            }}
          </div>
          <div
            class="flex justify-between md:justify-start md:gap-[170px] lg:gap-52"
          >
            <div class="text-[#FFD600] md:text-xl text-lg">
              STARTING BALANCE:
            </div>
            <div class="text-white text-xl">
              {{
                balanceGuessingData && !loading
                  ? "$" + balanceGuessingData.startingBalance
                  : ""
              }}
            </div>
          </div>
          <div
            class="flex justify-between md:justify-start md:gap-[48px] lg:gap-[90px]"
          >
            <div class="text-[#FFD600] md:text-xl text-lg">
              TOTAL BONUS BUYS FOR TODAY:
            </div>
            <div class="text-white text-xl">
              {{
                balanceGuessingData && !loading
                  ? ongoingPayoutData.length
                  : ""
              }}
            </div>
          </div>
          <div
            class="flex gap-4 justify-between flex-wrap md:justify-start md:gap-40 lg:gap-48"
          >
            <div class="text-[#FFD600] md:text-xl text-lg text-left w-fit">
              GUESS THE BALANCE:
            </div>
            <Form
              v-if="balanceGuessingData.status != 'active'"
              classs="flex flex-col gap-2"
              :validation-schema="schema"
              @submit="submitGuess"
              @invalid-submit="onInvalidSubmit"
            >
              <div
                class="flex flex-col lg:flex-row flex-wrap justify-center items-center md:gap-7 gap-4"
              >
                <div class="flex-col flex flex-wrap">
                  <Field
                    v-model="guess"
                    name="guess"
                    type="number"
                    class="w-full max-w-[257px] px-2"
                  />
                </div>
                <button
                  type="submit"
                  class="bg-[#3A3333] text-white text-xl font-bold px-2 w-fit"
                  style="font-family: Roboto, sans-serif"
                  v-if="!isGuessing"
                >
                  Submit
                </button>
                <beat-loader
                  v-else
                  :loading="isGuessing"
                  color="white"
                  size="20px"
                ></beat-loader>
              </div>
            </Form>
          </div>
        </div>
        <div class="w-full flex justify-between items-start gap-2">
          <div class="bg-[#3A3333] mb-10 py-3 px-4 gap-2">
            <router-link
              to="/balance-guess/list"
              class="text-lg md:text-xl font-bold text-white"
            >
              GO BACK
            </router-link>
          </div>
          <div class="bg-[#3A3333] mb-10 py-3 px-4 gap-2">
            <button
              @click="
                () =>
                  this.$router.push({
                    path: `/payout-guess/${this.$route.params.gtbId}/live`,
                  })
              "
              class="text-lg md:text-xl font-bold text-[#FFD600]"
            >
              GO TO GUESS THE PAYOUT FOR THE GTB SESSION
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { defineComponent } from "vue";
import { Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useTimer } from "vue-timer-hook";
import moment from "moment-timezone";
import axios from "axios";
import Swal from "sweetalert2";
import BeatLoader from "vue-spinner/src/BeatLoader.vue";

export default defineComponent({
  components: {
    Field,
    Form,
    BeatLoader,
  },
  data() {
    return {
      accessToken: localStorage.getItem("romieinc-access-token"),
      loading: false,
      today: moment().tz("America/New_York").format("MM/DD/YYYY"),
      balanceGuessingData: [],
      gtbGuess: [],
      ongoingPayoutData: [],
      guess: "",
      schema: Yup.object({
        guess: Yup.number()
          .required()
          .min(0)
          .label("Guess the balance is required"),
      }),
      timer: "",
      isGuessing: false,
    };
  },
  methods: {
    async getGtbData() {
      this.loading = true;
      await axios
        .get(
          `${process.env.VUE_APP_BASE_API}/api/guess-the-balance/details/${this.$route.params.gtbId}`,
          {
            headers: {
              "x-api-key": process.env.VUE_APP_X_API_KEY,
            },
          }
        )
        .then((response) => {
          this.balanceGuessingData = response.data.gtb;
          if (response.data.gtb.timerTimeStarted) {
            this.timer = useTimer(
              moment(response.data.gtb.timerTimeStarted)
                .utc()
                .add(response.data.gtb.timerDuration ?? 10, "minutes")
            );
          }
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
        });
    },
    async getGuessData() {
      await axios
        .get(
          `${process.env.VUE_APP_BASE_API}/api/guess-the-balance/guesses?gtbId=${this.$route.params.gtbId}`,
          {
            headers: {
              Authorization: `Bearer ${this.accessToken}`,
            },
          }
        )
        .then((response) => {
          this.gtbGuess = response.data.gtbGuesses;
          this.guess = response.data.gtbGuesses[0]?.guess ?? "";
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getOngoingPayoutData() {
      await axios
        .get(
          `${process.env.VUE_APP_BASE_API}/api/gtp/gtp-status?gtbId=${this.$route.params.gtbId}&status=ongoing`,
          {
            headers: {
              // Authorization: `Bearer ${this.accessToken}`,
              "x-api-key": process.env.VUE_APP_X_API_KEY,
            },
          }
        )
        .then((response) => {
          this.ongoingPayoutData = response.data.statusSlot;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async submitGuess() {
      try {
        if (!this.$store.state.isLoggedIn) {
          return this.$router.push({
            path: "/login",
          });
        }
        this.isGuessing = true;

        if (this.gtbGuess.length > 0) {
          throw {
            name: "ValidationError",
            message: "You have reached the maximum number of guesses",
          };
        }

        if (this.balanceGuessingData.status !== "ongoing") {
          throw {
            name: "ValidationError",
            message: "Guess the balance is not accepting guesses",
          };
        }

        if (!this.timer.isRunning) {
          throw { name: "TimerError", message: "Timer has ended!" };
        }

        const guessThePayout = await axios.post(
          `${process.env.VUE_APP_BASE_API}/api/guess-the-balance/guesses/add`,
          {
            gtbId: this.$route.params.gtbId,
            guess: this.guess,
          },
          {
            headers: {
              Authorization: `Bearer ${this.accessToken}`,
            },
          }
        );

        if (guessThePayout.status !== 200) {
          throw "Failed to submit guess";
        }

        Swal.fire({
          title: "Success!",
          text: "Successfully submitted guess!",
          icon: "success",
          confirmButtonText: "Okay",
          color: "#a5dc86",
          confirmButtonColor: "#a5dc86",
          background: "#121212",
          timer: 3000,
        });

        this.isGuessing = false;
        this.getGuessData();
      } catch (error) {
        if (error?.response?.status === 401) {
          localStorage.removeItem("vuex");
          localStorage.removeItem("romieinc-access-token");
          window.location.reload();
        }
        this.isGuessing = false;
        Swal.fire({
          title: "Error!",
          text: error?.response?.data?.message ?? error.message,
          icon: "error",
          confirmButtonText: "Okay",
          color: "#FF3D3D",
          confirmButtonColor: "#FF3D3D",
          background: "#121212",
          timer: 3000,
        });
      }
    },
    onInvalidSubmit({ errors }) {
      if (errors.guess) {
        Swal.fire({
          title: "Error!",
          text: "Please input valid guesses!",
          icon: "error",
          confirmButtonText: "Okay",
          color: "#FF3D3D",
          confirmButtonColor: "#FF3D3D",
          background: "#121212",
          timer: 3000,
        });
      }
    },
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    formatTimer(seconds) {
      if (seconds < 10) {
        return "0" + seconds;
      }
      return seconds;
    },
  },
  mounted() {
    this.getGtbData();
    this.getGuessData();
    this.getOngoingPayoutData();
  },
  watch: {
    timer(timer) {
      if (timer.isExpired.value) {
        this.timer.pause();
        console.warn("Timer is expired");
      }
    },
  },
});
</script>
<style>
.payout-guess-bg {
  background-image: url("../assets/red-black-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#payout-guess-header {
  background-image: url("../assets/red-clouds-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

input + span {
  display: block;
}
</style>
