<template>
  <section
    class="relative min-h-screen md:min-h-[150vh] challenges-bg z-0"
    id="challenges"
  >
    <div
      class="relative flex flex-col justify-center gap-y-6 items-center mx-4 pl-3 pt-16 lg:pt-24 xl:pt-40"
    >
      <div class="absolute top-0">
        <img
          src="../assets/Samurai-swords.png"
          class="max-h-[300px] lg:max-h-[480px] h-full w-auto"
        />
      </div>
      <div v-motion-pop-visible class="mt-4 lg:mt-10">
        <img
          class="h-full w-auto max-h-12 lg:max-h-[70px]"
          src="../assets/challenges-title.png"
          alt="chalenges"
        />
      </div>
      <!-- <div v-motion-pop-visible>
        <img
          class="h-full w-auto max-h-32 lg:max-h-[160px]"
          src="../assets/comingsoon-text.png"
          alt="chalenges"
        />
      </div> -->
      <div
        class="flex flex-col md:flex-row flex-wrap items-center justify-center gap-5 md:gap-8 my-28 md:my-40 mx-10"
      >
        <div v-motion-pop-visible>
          <a href="https://roobet.com/?ref=romieinc" target="_blank">
            <img
              class="max-w-[320px] md:max-w-[500px] h-auto"
              src="../assets/challengesposter1.png"
          /></a>
        </div>
        <!-- <div v-motion-pop-visible>
          <a href="https://roobet.com/game/hacksaw:1606" target="_blank">
          <img
            class="max-w-[200px] md:max-w-[300px] h-auto"
            src="../assets/challengecard2.png"
          /></a>
        </div>
        <div v-motion-pop-visible>
          <a href="https://roobet.com/game/hacksaw:1530" target="_blank">
          <img
            class="max-w-[200px] md:max-w-[300px] h-auto"
            src="../assets/challengecard3.png"
          /></a>
        </div>-->
      </div>
    </div>
    <div class="absolute top-10 left-0 -z-10">
      <img
        src="../assets/challenges-redmoon-bg-overlay-left.png"
        class="max-h-[300px] md:max-h-[400px] lg:max-h-[580px] h-full w-auto"
      />
    </div>
    <div class="absolute bottom-0 right-0 -z-10">
      <img
        src="../assets/challenges-redmoon-bg-overlay-right.png"
        class="max-h-[300px] md:max-h-[400px] lg:max-h-[580px] h-full w-auto"
      />
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      challenges: [],
    };
  },
};
</script>
<style scoped>
.challenges-bg {
  background-image: url("../assets/challenges-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
