import { createRouter, createWebHistory } from "vue-router";
import MainView from "../views/MainView.vue";
import ErrorView from "../views/ErrorView.vue";
import MainPage from "../pages/MainPage.vue";
import ProfilePage from "../pages/ProfilePage.vue";
import LoginPage from "@/pages/LoginPage.vue";
import PayoutGuessPrevPage from "@/pages/PayoutGuessPrevPage.vue";
import PayoutGuessLivePage from "@/pages/PayoutGuessLivePage.vue";
import BalanceListPage from "@/pages/BalanceListPage.vue";
import BalanceGuessLivePage from "@/pages/BalanceGuessLivePage.vue";
import store from "@/store";

const routes = [
  {
    path: "/",
    component: MainView,
    meta: { alreadyAuth: true },
    children: [
      {
        path: "/",
        alias: "/",
        name: "Main",
        component: MainPage,
      },
      {
        path: "/login",
        alias: "/login",
        name: "Login",
        component: LoginPage,
      },
      {
        path: "/profile",
        alias: "/profile",
        name: "Profile",
        meta: { requiresAuth: true },
        component: ProfilePage,
      },
      {
        path: "/balance-guess",
        children: [
          {
            path: "list",
            meta: { requiresAuth: false },
            component: BalanceListPage,
          },
          {
            path: ":gtbId/live",
            alias: ":gtbId/live",
            meta: { requiresAuth: false },
            component: BalanceGuessLivePage,
          },
        ],
      },
      {
        path: "/payout-guess",
        children: [
          {
            path: ":gtbId/prev",
            alias: ":gtbId/prev",
            meta: { requiresAuth: false },
            component: PayoutGuessPrevPage,
          },
          {
            path: ":gtbId/live",
            alias: ":gtbId/live",
            meta: { requiresAuth: false },
            component: PayoutGuessLivePage,
          },
        ],
      },
    ],
  },

  {
    path: "/:pathMatch(.*)*",
    name: "ErrorView",
    component: ErrorView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

// eslint-disable-next-line
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.state.isLoggedIn) {
    router.push({ path: "/login" });
  }

  next();
});

export default router;
