<template>
    <section class="h-screen page-bg">
        <!-- <div class="flex flex-col justify-center items-center pt-44 md:pt-44" style="font-family: Russo One, sans-serif">
            <div class="text-white uppercase text-xl md:text-3xl">Under Construction</div>
            <div class="text-white uppercase text-xl md:text-3xl">coming soon...</div>
        </div> -->
    </section>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    mounted() {
        if (localStorage.getItem('romieinc-access-token')) {
            this.$router.push('/')
        }
    }

})

</script>

<style scoped>
.page-bg {
    background-image: url("../assets/red-black-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>