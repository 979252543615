<template>
  <section class="h-full payout-guess-bg">
    <div v-if="!loading" class="pt-40 pb-10 md:py-20 xl:py-60">
      <div
        id="payout-guess-header"
        class="h-32 md:h-[205px] w-full relative flex items-center justify-center"
      >
        <img
          src="../assets/RomieInc-GTB-LOGO.png"
          class="md:h-[194px] w-auto h-32 absolute -top-24 md:-top-32"
        />
        <img
          src="../assets/guess-the-balance.png"
          class="h-[72px] w-auto z-[12]"
        />
        <img
          src="../assets/redblood-bg-top.png"
          class="h-20 md:h-[107px] w-auto absolute top-4 md:top-8 z-10"
        />
        <img
          src="../assets/redblood-bg-bottom.png"
          class="h-20 md:h-[107px] w-auto absolute top-12 md:top-20 z-[11]"
        />
      </div>
      <div
        class="mt-10 md:mt-20 flex flex-col gap-10 md:gap-[60px] justify-center items-center xl:px-[132px] px-5"
      >
      <button class="self-start rounded-[10px] text-white bg-[#FF3D3D] py-3 px-4" @click="refresh" :disabled="loading">
        REFRESH
      </button>
        <div
          class="flex flex-col items-center justify-center p-4 md:pt-5 md:pb-16 md:pl-9 bg-transparent font-['Roboto,_sans-serif'] font-bold text-lg md:text-xl gap-y-5"
        >
          <div
            class="relative flex flex-col justify-center p-2 gap-1 bg-[#121010]"
          >
            <div class="text-white -mt-6 text-center">TOTAL GTB SESSIONS</div>
            <div class="text-white text-center">{{ totalGtbSessions }}</div>
          </div>
          <div
            class="flex flex-row justify-center gap-10 md:gap-20 xl:gap-[155px]"
          >
            <div
              class="relative flex flex-col justify-center p-2 gap-1 bg-[#121010]"
            >
              <div class="text-white -mt-6 text-center">TOTAL GTB SPENT</div>
              <div class="text-white text-center">
                ${{ getTotalGtbSpent() }}
              </div>
            </div>
            <div
              class="relative flex flex-col justify-center p-2 gap-1 bg-[#121010]"
            >
              <div class="text-white -mt-6 text-center">TOTAL PAYOUT</div>
              <div class="text-white text-center">${{ getTotalPayout() }}</div>
            </div>
          </div>
        </div>
        <div
          class="bg-[rgb(18,16,16)] p-4 md:px-9 md:py-8 w-full max-w-[1260px] max-h-[520px] md:max-h-[720px] mx-auto overflow-auto"
        >
          <table class="w-full">
            <thead class="mb-5 w-full">
              <tr class="text-white">
                <th
                  class="w-28 text-inherit pr-4 text-xs md:text-base text-right bg-[rgb(18,16,16)]"
                >
                  STATUS
                </th>
                <th
                  class="w-32 text-inherit text-left pr-2 text-xs md:text-base"
                >
                  DATE
                </th>
                <th
                  class="w-32 text-inherit text-left pr-2 text-xs md:text-base"
                >
                  STARTING BALANCE
                </th>
                <th
                  class="w-32 text-inherit text-left pr-2 text-xs md:text-base"
                >
                  BIGGEST WIN
                </th>
                <th
                  class="w-32 text-inherit text-left pr-2 text-xs md:text-base"
                >
                  ENDING BALANCE
                </th>
                <th
                  class="w-32 text-inherit text-left pr-2 text-xs md:text-base"
                >
                  PROFIT/LOSS
                </th>
                <th class="w-28 text-inherit text-left text-xs md:text-base">
                  WINNER
                </th>
              </tr>
            </thead>
            <tbody class="">
              <tr
                v-for="(data, index) in balanceList"
                class="mt-4 cursor-pointer text-[#FFD600] text-xs md:text-base hover:scale-[1.01] hover:shadow-2xl hover:bg-white/10"
                @click="goToDetail(data)"
                :key="index"
              >
                <td
                  class="uppercase text-inherit py-2 pr-4 text-right rounded-l-[10px]"
                  :class="
                    index === balanceList.length - 1 ? 'pb-10 md:pb-[71px]' : ''
                  "
                >
                  {{ data.status }}
                </td>
                <td class="uppercase py-2 pr-2 text-inherit align-text-top">
                  {{ formatDate(data.createdAt) }}
                </td>
                <td class="text-inherit align-text-top">
                  ${{ data.startingBalance }}
                </td>
                <td class="text-inherit align-text-top">
                  ${{ data.biggestWin ?? 0 }}
                </td>
                <td class="text-inherit align-text-top">
                  {{
                    data.status == "done"
                      ? data.endingBalance
                        ? "$" + data.endingBalance
                        : ""
                      : "TBD"
                  }}
                </td>
                <td
                  class="text-inherit align-text-top"
                  :class="
                    data.status !== 'ongoing'
                      ? data.endingBalance - data.startingBalance > 0
                        ? 'text-green-500'
                        : 'text-red-500'
                      : ''
                  "
                >
                  {{
                    data.status == "done"
                      ? "$" +
                        Math.abs(data.endingBalance - data.startingBalance)
                      : "TBD"
                  }}
                </td>
                <td class="uppercase text-inherit align-text-top rounded-r-[10px]">
                  {{
                    data.status == "done"
                      ? data?.winners.length > 0
                        ? data?.winners[0].username
                        : ""
                      : "TBD"
                  }}
                </td>
              </tr>
              <tr
                v-for="(data, index) in balanceListDone"
                class="mt-4 cursor-pointer text-xs md:text-base hover:scale-[1.01] hover:shadow-lg hover:bg-white/10"
                :class="
                  data.status !== 'done' ? 'text-[#FFD600]' : 'text-white'
                "
                @click="goToDetail(data)"
                :key="index"
              >
                <td class="uppercase text-inherit pr-4 text-right rounded-l-[10px]">
                  {{
                    data.status == "started" ? "Started Playing" : data.status
                  }}
                </td>
                <td class="uppercase py-2 text-inherit">
                  {{ formatDate(data.createdAt) }}
                </td>
                <td class="text-inherit">${{ data.startingBalance }}</td>
                <td class="text-inherit">
                  {{
                    data.status == "done"
                      ? data.biggestWin > 0
                        ? "$" +  (Math.round(data.biggestWin * 10) / 10).toFixed(1)
                        : ""
                      : "TBD"
                  }}
                </td>
                <td class="text-inherit">
                  {{
                    data.status == "done"
                      ? data.endingBalance
                        ? "$" + data.endingBalance
                        : ""
                      : "TBD"
                  }}
                </td>
                <td
                  class="text-inherit"
                  :class="
                    data.status == 'done'
                      ? data.endingBalance - data.startingBalance > 0
                        ? 'text-green-500'
                        : 'text-red-500'
                      : ''
                  "
                >
                  {{
                    data.status == "done"
                      ? data.endingBalance
                        ? "$" +
                          (Math.round(Math.abs(data.endingBalance - data.startingBalance) * 10) / 10).toFixed(1)
                        : ""
                      : "TBD"
                  }}
                </td>
                <td class="uppercase text-inherit text-left rounded-r-[10px]">
                  {{
                    data.status == "done"
                      ? data?.winners.length > 0
                        ? data?.winners[0].username
                        : ""
                      : "TBD"
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      v-else
      class="bg-transparent flex justify-center py-40 md:py-60 w-full max-w-[1260px] mx-auto overflow-x-auto"
    >
      <bounce-loader :loading="loading" size="80px" color="#fff" />
    </div>
  </section>
</template>
<script>
import axios from "axios";
import moment from "moment-timezone";
import { defineComponent } from "vue";
import BounceLoader from "vue-spinner/src/BounceLoader.vue";

export default defineComponent({
  components: {
    BounceLoader,
  },
  data() {
    return {
      loading: false,
      balanceList: [],
      balanceListDone: [],
      payoutDataDone: [],
    };
  },
  methods: {
    async getBalanceList() {
      try {
        this.loading = true;
        const balanceList = await axios.get(
          `${process.env.VUE_APP_BASE_API}/api/guess-the-balance`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": process.env.VUE_APP_X_API_KEY,
            },
          }
        );

        if (balanceList.status !== 200) {
          throw "Error fetching balance list";
        }

        this.balanceList = balanceList.data.gtbs.filter(
          (item) => item.status == "ongoing"
        );
        this.balanceListDone = balanceList.data.gtbs.filter(
          (item) => item.status !== "ongoing"
        );
        this.totalGtbSessions = balanceList.data.gtbs.filter(
          (item) => item.status == "done"
        ).length;
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    async getAllPayoutData() {
      try {
        const payoutDataDone = await axios.get(
          `${process.env.VUE_APP_BASE_API}/api/gtp/total-payout?status=done`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": process.env.VUE_APP_X_API_KEY,
            },
          }
        );

        if (payoutDataDone.status !== 200) {
          throw "Error fetching balance list";
        }
        this.payoutDataDone = payoutDataDone.data.doneSlots;
      } catch (error) {
        console.error(error);
      }
    },
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    goToDetail(data) {
      if (data.status != "done") {
        return this.$router.push({ path: `/balance-guess/${data._id}/live` });
      }

      return this.$router.push({ path: `/payout-guess/${data._id}/prev` });
    },
    getTotalGtbSpent() {
      return (Math.round(this.balanceListDone.reduce((acc, curr) => {
        return acc + curr.startingBalance;
      }, 0)* 10) / 10).toFixed(1);
    },
    getTotalPayout() {
      return (Math.round(this.payoutDataDone.reduce((acc, curr) => {
        return acc + curr.bonusBuyPayout;
      }, 0)* 10) / 10).toFixed(1);
    },
    refresh() {
      this.getBalanceList();
      this.getAllPayoutData();
    },
  },
  mounted() {
    this.getBalanceList();
    this.getAllPayoutData();
  },
});
</script>
<style>
.payout-guess-bg {
  background-image: url("../assets/red-black-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#payout-guess-header {
  background-image: url("../assets/red-clouds-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* thead tr th {
  position: sticky;
  top: 0;
  z-index: 10;
} */
</style>
