<template>
  <div>
    <!-- Main section start -->
    <section class="h-full main-bg">
      <div
        class="relative flex-col flex flex-nowrap items-center justify-center w-full place-items-end pt-32 md:pt-32 p-8 px-2 sm:px-0 xl:p-8 lg:pt-[14rem] xl:pt-[200px] 2xl:pt-[40rem]"
      >
        <div
          class="relative flex justify-center items-center gap-y-8 md:gap-y-20 lg:gap-y-24 2xl:gap-y-20 xl:mt-[0rem] 2xl:mt-[-24rem] 2xl:pt-[0rem] flex-col sm:flex-col w-full"
        >
          <div class="-z-[1px] absolute lg:-top-[8rem] xl:-top-20">
            <img
              src="../assets/redmoon-hero-section.png"
              class="max-w-[720px] max-h-[350px] md:max-h-[500px] xl:max-h-[625px] h-full w-full"
              width="720"
              height="625"
              alt="red moon"
            />
          </div>
          <div
            class="mt-4 xl:mt-10 sm:mt-0 lg:ml-2 xl:ml-4 2xl:-ml-5 3xl:mt-16"
          >
            <a href="https://roobet.com/?ref=romieinc" target="_blank">
              <img
                v-motion-slide-visible-left
                class="h-full w-auto max-h-18 md:max-h-20 lg:max-h-[90px] xl:max-h-[150px]"
                src="../assets/ROMIEINC-logo.png"
                width="1005"
                height="150"
                alt="romieinc"
              />
            </a>
          </div>
          <div
            class="absolute translate-x-0 translate-y-[10%] md:translate-x-[5%] md:translate-y-[5%] xl:translate-y-[30%] 2xl:translate-y-[40%]"
          >
            <a href="https://roobet.com/?ref=romieinc" class="" target="_blank">
              <img
                v-motion-pop-visible
                src="../assets/X-TEXT.png"
                class="w-auto h-full max-h-20 md:max-h-[100px] lg:max-h-[120px]"
                width="450"
                height="110"
                alt="x"
              />
            </a>
          </div>
          <div
            class="mt-10 sm:mt-12 lg:mt-0 lg:-mr-20 xl:mt-2 2xl:mt-12 flex justify-center lg:justify-end lg:place-content-end"
          >
            <a href="https://roobet.com/?ref=romieinc" target="_blank">
              <img
                v-motion-slide-visible-right
                src="../assets/roobet-herosection-text.png"
                class="hidden w-auto max-h-16 md:max-h-16 xl:max-h-[132px] h-full md:block"
                width="600"
                height="150"
                alt="roobet"
              />
              <img
                v-motion-pop-visible
                src="../assets/roobet-herosection-text.png"
                class="w-auto max-h-10 md:max-h-16 xl:max-h-[132px] h-full block md:hidden"
                width="600"
                height="150"
                alt="roobet"
              />
            </a>
          </div>
        </div>
      </div>
      <div
        class="relative flex flex-col justify-center items-center gap-5 lg:gap-6 font-['Russo_One']"
      >
        <div class="absolute top-10 md:top-0 left-0">
          <img
            src="../assets/samurai-silhouette.png"
            class="max-h-[35rem] md:max-h-[60rem] xl:max-h-[75rem] h-full w-auto"
          />
        </div>
        <div class="absolute bottom-0 md:top-0 right-0">
          <img
            src="../assets/hand-drawn-chinese-style-illustration.png"
            class="max-h-[35rem] md:max-h-[60rem] xl:max-h-[75rem] h-full w-auto"
          />
        </div>
        <div
          class="mt-48 mb-[15rem] xl:mb-[30rem] pt-24 md:pt-48 pb-20 lg:p-0 xl:mt-[18rem] 2xl:mt-[20rem] xl:pb-[119px] flex flex-col justify-center items-center"
        >
          <h3
            class="drop-shadow-[black_10px_20px_10px] text-white text-xl md:text-[25px] leading-[35px] tracking-[17%] mx-auto text-center relative z-0"
            data-title="MONTHLY LEADERBOARD REWARDS UP FOR GRABS"
          >
            MONTHLY LEADERBOARD REWARDS UP FOR GRABS
          </h3>
          <!-- <div>
          <img v-motion-pop-visible src="../assets/be-part.png" alt="be-part" />
        </div> -->
          <div>
            <a href="https://roobet.com/?ref=romieinc" target="_blank">
              <img
                v-motion-pop-visible
                src="../assets/use-code.png"
                class="max-h-20 md:max-h-28 xl:max-h-40 h-full w-auto"
                alt="vivstream-link-text"
              />
            </a>
          </div>
          <div
            class="text-white text-xl md:text-[25px] leading-[35px] tracking-[17%] flex flex-col items-center justify-center"
          >
            <h3
              class="text-inherit drop-shadow-[black_0_20px_10px] text-shadow"
            >
              LEADERBOARD RESETS EVERY
            </h3>
            <h3
              class="text-inherit drop-shadow-[black_0_20px_10px] text-shadow"
            >
              MONTH SO MAKE SURE YOU
            </h3>
            <h3
              class="text-inherit drop-shadow-[black_0_20px_10px] text-shadow"
            >
              GET SIGNED UP!
            </h3>
          </div>
        </div>
      </div>
    </section>

    <LeaderboardSection />

    <section
      class="relative h-auto social-bg bg-black shadow-[0px_0px_25px_0px_red] z-10"
      id="socials"
    >
      <div class="absolute top-20 lg:top-[137px] left-0">
        <img
          src="../assets/red-samurai-romieinc-image-left.png"
          class="max-h-[300px] md:max-h-[550px] xl:max-h-[770px] h-full w-auto"
        />
      </div>
      <div class="absolute top-20 lg:top-[137px] right-0">
        <img
          src="../assets/red-samurai-romieinc-image-right.png"
          class="max-h-[300px] md:max-h-[550px] xl:max-h-[770px] h-full w-auto"
        />
      </div>
      <div
        class="flex flex-col justify-center items-center py-36 md:pt-[16rem] lg:pt-[274px] lg:pb-[25rem] md:pb-[18rem]"
      >
        <div class="flex justify-center">
          <img
            v-motion-pop-visible
            src="../assets/linkstomysocials-text.png"
            class="lg:px-52"
            alt="socials-title"
          />
        </div>
        <div
          class="flex flex-col lg:flex-row justify-center items-center pb-14 lg:gap-24 md:mt-4 lg:mt-8 md:pb-24 lg:pb-14"
        >
          <div
            v-motion-pop-visible
            class="flex px-4 md:px-14 flex-wrap items-center justify-center gap-2 lg:gap-[70px] py-5 mt-5 lg:mt-0 lg:py-11 bg-gradient-to-r from-[#A10000] via-[#0E0E00_48%] to-[#A10000] rounded-3xl"
            style="box-shadow: inset 0 0 15px black"
          >
            <a href="https://x.com/RomieINC" target="_blank">
              <img
                v-motion-slide-visible-bottom
                class="w-10 h-10 lg:h-[50px] lg:w-[50px]"
                src="../assets/twitter.png"
                alt="twitter"
              />
            </a>
            <a href="https://www.youtube.com/@romieinc" target="_blank">
              <img
                v-motion-slide-visible-bottom
                class="w-10 h-10 lg:h-[50px] lg:w-[50px] grayscale"
                src="../assets/youtube.png"
                alt="facebook"
              />
            </a>
            <a
              href="https://www.instagram.com/romieinc_/profilecard/?igsh=aXJsb3ltOXR6OXFn"
              target="_blank"
            >
              <img
                v-motion-slide-visible-bottom
                class="w-10 h-10 lg:h-[50px] lg:w-[50px]"
                src="../assets/instagram.png"
                alt="instagram"
              />
            </a>
            <a href="https://kick.com/romielnc" target="_blank">
              <img
                v-motion-slide-visible-bottom
                class="w-10 h-10 lg:h-[50px] lg:w-[50px]"
                src="../assets/kick.png"
                alt="kick"
              />
            </a>
            <a href="https://discord.com/invite/3naeDn6kWX" target="_blank">
              <img
                v-motion-slide-visible-bottom
                class="w-10 h-10 lg:h-[50px] lg:w-[50px]"
                src="../assets/discord.png"
                alt="discord"
              />
            </a>
            <a href="#" target="_blank">
              <img
                v-motion-slide-visible-bottom
                class="w-10 h-10 lg:h-[50px] lg:w-[50px]"
                src="../assets/tiktok.png"
                alt="tiktok"
              />
            </a>
          </div>
        </div>
      </div>
    </section>

    <ChallengesSection />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import LeaderboardSection from "@/sections/LeaderboardSection.vue";
import ChallengesSection from "@/sections/ChallengesSection.vue";

export default defineComponent({
  components: {
    LeaderboardSection,
    ChallengesSection,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {},
});
</script>

<style scope>
body {
  background-color: #0b0b0c;
}
.main-bg {
  background-image: url("../assets/combined-hero-section bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* Add drop shadow top  */
.text-shadow::before {
  content: attr(data-title);
  position: absolute;
  z-index: -1;
  filter: drop-shadow(black 0px -20px 10px);
}
</style>
