<template>
  <section id="profile" class="h-full">
    <div class="flex px-4 md:px-10 py-20 md:py-40 flex-col gap-5 xl:px-20 max-w-[1080px] mx-auto">
      <div class="self-end" v-if="isLogout">
        <a
          href="javascript:void(0)"
          @click="logout"
          class="no-underline text-xl md:text-[25px] leading-6 text-[#FF3D3D]"
          style="font-family: Russo One, sans-serif"
          >LOGOUT</a
        >
      </div>
      <div class="w-full p-5 bg-[#300000]">
        <div
          class="w-full pt-6 md:pt-[47px] pb-6 p-5 xl:pl-[30px] xl:pr-[43px] bg-[#0F0000]"
        >
          <div
            class="self-start flex-col flex md:flex-row gap-5 md:gap-[30px] mb-10 md:mb-20"
          >
            <div>
              <img
                src="../assets/user-account.png"
                class="h-20 md:h-[200px] w-auto object-contain"
              />
            </div>
            <div
              class="w-full text-white flex flex-col md:gap-y-2 max-w-[370px]"
              style="font-family: Saira Condensed, sans-serif"
            >
              <h1 class="font-extrabold text-xl md:text-[50px] text-inherit">
                PLAYER #{{ this.$store.state.user.discordId.substring(0, 5) }}
              </h1>
              <h2
                class="uppercase font-bold text-lg md:text-[30px] text-[#72756C]"
              >
                discord id: &nbsp;&nbsp;
                {{ this.$store?.state?.user?.username ?? "" }}
              </h2>
              <form class="mt-2 space-y-2 w-full">
                <div class="flex justify-between w-full">
                  <label class="text-lg md:text-[30px] font-bold text-white"
                    >KICK:</label
                  >
                  <input
                    type="text"
                    class="w-full md:w-auto bg-transparent text-white text-lg px-2"
                    :class="{
                      'border-none': !isEdit,
                      'border-gray-300 border': isEdit,
                    }"
                    v-model="kickId"
                    :disabled="!isEdit"
                  />
                </div>
                <div class="flex justify-between w-full">
                  <label class="text-lg md:text-[30px] font-bold text-white"
                    >ROOBET:</label
                  >
                  <input
                    type="text"
                    class="md:w-auto w-full bg-transparent text-white text-lg px-2"
                    :class="{
                      'border-none': !isEdit,
                      'border-gray-300 border': isEdit,
                    }"
                    v-model="roobetId"
                    :disabled="!isEdit"
                  />
                </div>
                <div class="flex justify-between w-full">
                  <label class="text-lg md:text-[30px] font-bold text-white"
                    >CHICKEN.GG:</label
                  >
                  <input
                    type="text"
                    class="md:w-auto w-full bg-transparent text-white text-lg px-2"
                    :class="{
                      'border-none': !isEdit,
                      'border-gray-300 border': isEdit,
                    }"
                    v-model="chickengg"
                    :disabled="!isEdit"
                  />
                </div>
                <div class="flex justify-between max-w-[370px]">
                  <label class="text-lg md:text-[30px] font-bold text-white"
                    >TWITTER:</label
                  >
                  <input
                    type="text"
                    class="w-full md:w-auto bg-transparent text-white text-lg px-2"
                    :class="{
                      'border-none': !isEdit,
                      'border-gray-300 border': isEdit,
                    }"
                    v-model="twitter"
                    :disabled="!isEdit"
                  />
                </div>
              </form>
            </div>
          </div>
          <div class="flex justify-between">
            <div
              class="text-xs md:text-[15px] uppercase text-[#FFE500]"
              style="font-family: Poppins, sans-serif"
            >
              <h2 class="uppercase text-inherit">disclaimer:</h2>
              <h3 class="text-inherit">
                - Users must put the correct information to avoid discrepancies
                in points or else points won’t be distributed correctly
                <br />
                - any loss of points, the romieinc team will not be held liable
                due to incorrect information entered on profile page (this is
                <br />
                also a way to prevent alt accounts farming for points) so please
                be careful in entering the right information
              </h3>
            </div>
            <div class="flex items-center font-saira-condensed gap-3.5">
              <button
                type="button"
                class="py-4 px-5 text-lg text-white bg-[#120A02] border border-[#2C2218]"
                v-if="!isEdit && !isLoading"
                @click="isEdit = true"
              >
                EDIT
              </button>
              <button
                type="button"
                class="py-4 px-5 text-lg text-white bg-[#120A02] border border-[#2C2218]"
                :class="{ hidden: !isEdit && isLoading }"
                v-if="isEdit && !isLoading"
                @click="editProfile"
              >
                SAVE
              </button>
              <pulse-loader
                v-else
                :loading="isLoading"
                :color="color"
                :size="size"
              ></pulse-loader>
            </div>
          </div>
        </div>
      </div>
      <div class="my-10">
        <h1
          class="text-[#FF3D3D] text-lg md:text-[25px]"
          style="font-family: Russo One, sans-serif"
        >
          NOTIFICATIONS
        </h1>
        <div class="flex flex-row gap-4 py-5 px-4 font-saira-condensed">
          <ul
            class="flex flex-col items-center p-2rounded-lg bg-transparent list-disc text-white gap-2 max-h-[500px] overflow-y-auto"
          >
            <li v-for="(notif, index) in notificationsToday" class="space-y-2 w-full" :key="index">
              <div class="text-lg md:text-[25px] font-bold text-white">
                You’ve won guess the {{ notif.type == 'gtb' ? 'balance' : 'payout' }} on {{ formatDate(notif.createdAt) }} please go to discord,
                make a ticket and claim your prize! {{ notif.type == 'overall' ? notif.message : '' }}
              </div>
            </li>
            <li v-for="(notif, index) in notifications" class="space-y-2" :key="index">
              <div
                class="text-lg md:text-[25px] font-bold text-white opacity-70"
              >
                You’ve won guess the {{ notif.type == 'gtb' ? 'balance' : 'payout' }} on {{ formatDate(notif.createdAt) }} please go to discord,
                make a ticket and claim your prize! {{ notif.type == 'overall' ? notif.message : '' }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import Swal from "sweetalert2";
import moment from "moment-timezone";

export default {
  components: {
    PulseLoader,
  },
  data() {
    return {
      isScrolled: false,
      isLogout: true,
      showMenu: false,
      isLoggedIn: false,
      isEdit: false,
      isLoading: false,
      color: "#ffffff",
      size: "10px",
      kickId: "",
      discordId: "",
      roobetId: "",
      chickengg: "",
      accessToken: localStorage.getItem("accessToken"),
      twitter: "",
      notifications: [],
      notificationsToday: [],
      isLoadingNotif: false,
    };
  },
  methods: {
    async editProfile() {
      this.isLoading = true;
      try {
        const formData = {
          userId: this.$store.state.user._id,
          kick: this.kickId,
          roobet: this.roobetId,
          chickengg: this.chickengg,
          twitter: this.twitter,
        };

        const profileUpdate = await axios.put(
          `${process.env.VUE_APP_BASE_API}/api/user/update`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${this.accessToken}`,
              // 'Content-Type': 'multipart/form-data',
            },
          }
        );

        if (profileUpdate.status !== 200) {
          throw "Profile update failed!";
        }

        Swal.fire({
          title: "Success!",
          text: "Successfully updated your profile!",
          icon: "success",
          confirmButtonText: "Okay",
          color: "#a5dc86",
          confirmButtonColor: "#a5dc86",
          background: "#121212",
          timer: 3000,
        });

        this.isEdit = false;
        this.isLoading = false;
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.removeItem("vuex");
          localStorage.removeItem("romieinc-access-token");
          window.location.reload();
        }
        this.isLoading = false;
        console.error(error);
      }
    },
    async getProfile() {
      try {
        this.isLoading = true;
        const profile = await axios.get(
          `${process.env.VUE_APP_BASE_API}/api/user`,
          {
            headers: {
              Authorization: `Bearer ${this.accessToken}`,
            },
          }
        );

        if (profile.status !== 200) {
          throw "Profile not found!";
        }

        this.kickId = profile.data.user.kick;
        this.roobetId = profile.data.user.roobet;
        this.chickengg = profile.data.user.chickengg;
        this.twitter = profile.data.user.twitter;
        this.isLoading = false;
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.removeItem("vuex");
          localStorage.removeItem("romieinc-access-token");
          this.$router.push("/login");
        }
        this.isLoading = false;
        console.error(error);
      }
    },
    async getNotifications() {
      try {
        this.isLoadingNotif = true;
        const notif = await axios.get(
          `${process.env.VUE_APP_BASE_API}/api/notification/gtp-notification`,
          {
            headers: {
              Authorization: `Bearer ${this.accessToken}`,
            },
          }
        );

        this.notificationsToday = notif.data?.gtpNotification[0]?.notificationItem.filter((notif) => {
          const today = moment().utc();

          return moment(moment(notif.createdAt).utc()).isSame(today, 'day') && (notif.type == 'gtb' || notif.type == 'overall');
        })
        console.log(this.notificationsToday, 'notif')
        this.notifications = notif.data?.gtpNotification[0]?.notificationItem.filter((notif) => {
          const today = moment().utc();

          return !moment(moment(notif.createdAt).utc()).isSame(today, 'day') && (notif.type == 'gtb' || notif.type == 'overall');
        })
        this.isLoadingNotif = false;
      } catch (error) {
        if (error?.response?.status === 401) {
          localStorage.removeItem("vuex");
          localStorage.removeItem("romieinc-access-token");
          window.location.reload();
        }
        this.isLoadingNotif = false;
        console.error(error);
      }
    },
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY hh:mm");
    },
    logout() {
      this.isLogout = false;
      this.isLoggedIn = false;
      this.$store.commit("logout");
      localStorage.removeItem("romieinc-access-token");
      window.location.href = "/login";
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 0;
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
  mounted() {
    this.accessToken = localStorage.getItem("romieinc-access-token");
    window.addEventListener("scroll", this.handleScroll);
    this.getProfile();
    this.getNotifications();
  },
};
</script>
<style>
#profile {
  background-image: url("../assets/red-black-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
