<template>
  <section class="h-full payout-guess-bg">
    <div v-if="!loading" class="pt-40 md:pt-60">
      <div
        id="payout-guess-header"
        class="h-32 md:h-[205px] w-full relative flex items-center justify-center"
      >
        <img
          src="../assets/RomieInc-GTB-LOGO.png"
          class="md:h-[194px] w-auto h-32 absolute -top-24 md:-top-32"
        />
        <img
          src="../assets/guess-the-payout.png"
          class="h-[72px] w-auto z-[12]"
        />
        <img
          src="../assets/redblood-bg-top.png"
          class="h-20 md:h-[107px] w-auto absolute top-4 md:top-8 z-10"
        />
        <img
          src="../assets/redblood-bg-bottom.png"
          class="h-20 md:h-[107px] w-auto absolute top-12 md:top-20 z-[11]"
        />
      </div>
      <div
        class="font-saira-condensed mt-10 md:mt-14 flex flex-col gap-10 md:gap-[60px] justify-center items-center px-4 xl:px-0 max-w-[1080px] mx-auto"
      >
        <div
          class="w-full p-2 text-justify bg-[#FF3D3D] text-white font-normal bg-opacity-100 text-sm flex md:flex-row flex-col justify-between gap-5 uppercase"
        >
          CONDITION: WINNERS HERE ARE DETERMINED THROUGH EARNED POINTS THE
          ENTIRE SESSION. THE HIGHER THE POINTS, THE HIGHER THE CHANCE YOU GET
          TO TOP 3. POINTS SYSTEM: EXACT GUESS IS 10 POINTS, $2 OFF IS 3 POINTS,
          AND $5 OFF IS 1 POINT.<br />
          Disclaimer: Guess the Balance (GTB) and Guess the Payout (GTP) are
          exclusive for players under Romieinc code. If your guess/guesses are
          right, got enough points, and eventually declared as one of winners,
          you'll get no prize if you're not under the code.
        </div>
        <div
          class="w-full p-4 md:pt-5 md:pb-16 md:px-9 bg-[rgb(18,16,16)] font-bold text-lg md:text-xl flex md:flex-row flex-col justify-between gap-5"
        >
          <div class="flex flex-col gap-y-5">
            <div class="text-white">
              DATE: {{ formatDate(balanceData.createdAt) }}
            </div>
            <div class="flex justify-between md:justify-start gap-40">
              <div class="text-white">STARTING BALANCE:</div>
              <div class="text-white">${{ balanceData.startingBalance }}</div>
            </div>
            <div class="flex justify-between md:justify-start gap-40">
              <div class="text-white">TOTAL BONUS BUYS:</div>
              <div class="text-white">{{ payoutData.length ?? 0 }}</div>
            </div>
          </div>
          <div class="flex flex-col gap-4 mt-5 md:mt-0 text-white font-normal">
            <h3 class="text-white hover:cursor-pointer">
              OVERALL GUESS THE PAYOUT WINNERS
            </h3>
            <div
              v-for="(winner, index) in balanceData?.gtpOverallWinners?.slice(
                0,
                3
              )"
              :key="index"
            >
              <div class="text-white">
                {{ rankString(index + 1) }}:&nbsp;&nbsp;&nbsp;
                {{ winner.user }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="bg-[rgb(18,16,16)] p-4 md:px-9 md:py-8 w-full max-w-[1260px] mx-auto overflow-x-auto"
        >
          <table class="w-full">
            <thead class="mb-5">
              <tr class="text-white">
                <th
                  class="w-40 text-inherit text-xs md:text-base text-left pr-2 pl-3"
                >
                  SLOT NAME
                </th>
                <th
                  class="w-32 text-inherit text-xs md:text-base text-left pr-2"
                >
                  STATUS
                </th>
                <th
                  class="w-32 text-inherit text-xs md:text-base text-left pr-2"
                >
                  BONUS BUY COST
                </th>
                <th
                  class="w-24 text-inherit text-xs md:text-base text-left pr-2"
                >
                  PAYOUT
                </th>
                <th
                  class="w-24 text-inherit text-xs md:text-base text-left pr-2"
                >
                  PROFIT
                </th>
                <th class="w-28 text-inherit text-xs md:text-base text-left">
                  WINNER
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in payoutData"
                class="text-white mt-4 hover:scale-[1.01] hover:shadow-2xl hover:bg-white/10"
                :key="index"
              >
                <td
                  class="uppercase py-2 pr-2 text-inherit text-xs md:text-base pl-3 rounded-l-[10px]"
                >
                  {{ item.slotTitle }}
                </td>
                <td class="uppercase text-inherit text-xs md:text-base">
                  {{ item.status }}
                </td>
                <td class="text-inherit text-xs md:text-base">
                  ${{ item.bonusBuyCost }}
                </td>
                <td class="text-inherit text-xs md:text-base">
                  ${{ item.bonusBuyPayout }}
                </td>
                <td
                  class="text-inherit text-xs md:text-base"
                  :class="
                    item.status !== 'ongoing'
                      ? item.profit > 0
                        ? 'text-green-500'
                        : 'text-red-500'
                      : ''
                  "
                >
                  ${{ item.profit ? (Math.round(Math.abs(item.profit) * 10) / 10).toFixed(1) : 0 }}
                </td>
                <td class="uppercase text-inherit text-xs md:text-base rounded-r-[10px]">
                  {{ item.winner[0]?.user ?? "" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="bg-[rgb(18,16,16)] p-4 md:px-9 md:py-8 w-full max-w-[1260px] mx-auto overflow-x-auto"
        >
          <h2 class="text-white font-bold text-center text-xl pl-3">MY GUESS</h2>
          <div class="flex justify-start gap-5 mt-5 pl-3">
            <h4 class="text-white font-bold">ENDING BALANCE:</h4>
            <h4 class="text-white font-bold">
              {{ gtbGuess?.guess ? "$" + gtbGuess.guess : "" }}
            </h4>
          </div>
          <div class="mt-5">
            <table class="w-full max-w-[720px]">
              <thead class="mb-5">
                <tr class="text-white">
                  <th
                    class="w-40 text-inherit text-xs md:text-base text-left pr-2 pl-3"
                  >
                    SLOT NAME
                  </th>
                  <th
                    class="w-3 text-inherit text-xs md:text-base text-left pr-2"
                  >
                    GUESS
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in payoutGuessData"
                  :key="index"
                  class="text-white mt-4 hover:scale-[1.01] hover:shadow-2xl hover:bg-white/10"
                >
                  <td
                    class="uppercase py-2 pr-2 text-inherit text-xs md:text-base pl-3 rounded-l-[10px]"
                  >
                    {{ item?.gtpSlotName }}
                  </td>
                  <td class="uppercase text-inherit text-xs md:text-base rounded-r-[10px]">
                    {{ item?.gtpUserGuess ? "$" + item.gtpUserGuess : "" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="self-start bg-[#3A3333] mb-10 py-3 px-4">
          <router-link
            to="/balance-guess/list"
            class="text-xl font-bold text-white"
          >
            GO BACK
          </router-link>
        </div>
      </div>
    </div>
    <div
      v-else
      class="bg-transparent flex justify-center py-40 md:py-60 w-full max-w-[1260px] mx-auto overflow-x-auto"
    >
      <bounce-loader :loading="loading" size="80px" color="#fff" />
    </div>
  </section>
</template>
<script>
import axios from "axios";
import moment from "moment-timezone";
import { defineComponent } from "vue";
import BounceLoader from "vue-spinner/src/BounceLoader.vue";

export default defineComponent({
  components: {
    BounceLoader,
  },
  data() {
    return {
      loading: false,
      balanceData: {},
      payoutData: [],
      gtbGuess: null,
      payoutGuessData: [],
      accessToken: localStorage.getItem("romieinc-access-token"),
      today: moment().tz("America/New_York").format("MM/DD/YYYY"),
    };
  },
  methods: {
    async getGtbData() {
      await axios
        .get(
          `${process.env.VUE_APP_BASE_API}/api/guess-the-balance/details/${this.$route.params.gtbId}`,
          {
            headers: {
              "x-api-key": process.env.VUE_APP_X_API_KEY,
            },
          }
        )
        .then((response) => {
          this.balanceData = response.data.gtb;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getPayoutData() {
      try {
        this.loading = true;
        const payoutData = await axios.get(
          `${process.env.VUE_APP_BASE_API}/api/gtp/gtp-status?gtbId=${this.$route.params.gtbId}&status=done`,
          {
            headers: {
              // Authorization: `Bearer ${this.accessToken}`,
              "x-api-key": process.env.VUE_APP_X_API_KEY,
            },
          }
        );

        if (payoutData.status !== 200) {
          throw "Failed to get payout data";
        }

        this.payoutData = payoutData.data.statusSlot;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error(error);
      }
    },
    async getGtbGuessData() {
      await axios
        .get(
          `${process.env.VUE_APP_BASE_API}/api/guess-the-balance/guesses?gtbId=${this.$route.params.gtbId}`,
          {
            headers: {
              Authorization: `Bearer ${this.accessToken}`,
            },
          }
        )
        .then((response) => {
          this.gtbGuess = response?.data?.gtbGuesses[0] ?? [];
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getPayoutGuessData() {
      try {
        this.loading = true;
        const payoutGuessData = await axios.get(
          `${process.env.VUE_APP_BASE_API}/api/gtp/gtp-guesses?gtbId=${this.$route.params.gtbId}&status=done`,
          {
            headers: {
              Authorization: `Bearer ${this.accessToken}`,
              // "x-api-key": process.env.VUE_APP_X_API_KEY,
            },
          }
        );

        if (payoutGuessData.status !== 200) {
          throw "Failed to get payout data";
        }

        this.payoutGuessData = payoutGuessData.data.userGuess.filter(
          (item) => item.user._id == this.$store.state.user._id
        );
        if (this.payoutGuessData.length === this.payoutData.length) {
          this.payoutGuessData.forEach((item, index) => {
            item.gtpSlotName = this.payoutData[index].slotTitle;
          });
        }

        console.log(payoutGuessData);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error(error);
      }
    },
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    rankString(rank) {
      if (rank === 1) {
        return "1ST";
      } else if (rank === 2) {
        return "2ND";
      } else if (rank === 3) {
        return "3RD";
      } else {
        return rank + "TH";
      }
    },
  },
  mounted() {
    this.getGtbData();
    this.getPayoutData();
    this.getGtbGuessData();
    this.getPayoutGuessData();
  },
});
</script>
<style>
.payout-guess-bg {
  background-image: url("../assets/red-black-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#payout-guess-header {
  background-image: url("../assets/red-clouds-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
