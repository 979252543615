<template>
  <div>
    <!-- Leaderboard Section -->
    <section class="h-auto leaderboard-bg">
      <div class="flex justify-center px-0 lg:pt-[6rem]">
        <div
          class="flex flex-col justify-center items-center mt-8 lg:mt-0 mb-14 lg:mb-16 p-8 px-0 pt-0 rounded-3xl"
          id="leaderboard"
        >
          <div
            v-show="selectedLeaderboard == false"
            class="flex flex-row justify-center items-center"
          >
            <img
              v-motion-pop-visible
              src="../assets/leaderboard-title.png"
              class="max-h-[100px] lg:max-h-[120px] xl:max-h-[150px] h-full w-auto"
              alt="leaderboard"
            />
            <a
              href="https://roobet.com/?ref=romieinc"
              class="self-end"
              target="_blank"
              ><img
                v-motion-pop-visible
                src="../assets/RomieIncXRoobetLogo.png"
                class="hidden md:block md:max-w-[350px] xl:max-w-[400px] w-full h-auto"
                alt="logo"
            /></a>
            <!-- <div class="text-center text-white font-light mt-4" style="font-family: Russo One, sans-serif;">{{ timer }}</div> -->
          </div>
          <div
            v-show="selectedLeaderboard == true"
            class="flex flex-row justify-center items-center mt-10"
          >
            <a
              href="https://packdraw.com/?ref=romieinc"
              class="self-start"
              target="_blank"
              ><img
                v-motion-pop-visible
                src="../assets/RomieIncXChickenLogo.png"
                class="hidden md:block max-w-[300px] md:max-w-[350px] xl:max-w-[410px] w-full h-auto"
                alt="packdraw-logo"
            /></a>
            <img
              v-motion-pop-visible
              src="../assets/leaderboard-title-chicken.png"
              class="px-5 max-h-[100px] lg:max-h-[120px] xl:max-h-[150px] h-full w-auto"
              alt="Packdraw leaderboard"
            />
          </div>
          <div
            class="mt-4 md:-mt-16 lg:-mt-24 xl:-mt-28 flex flex-col items-center"
          >
            <div
              v-motion-slide-visible-bottom
              class="text-center text-white font-medium px-8 py-2 text-[15px]"
              :class="
                selectedLeaderboard == true
                  ? 'bg-gradient-to-r from-[#1B0202] via-[#330000_50%] to-[#1B0202] mt-4'
                  : 'bg-gradient-to-r from-[#1B0202] via-[#330000_50%] to-[#1B0202]'
              "
              style="font-family: Russo One, sans-serif"
            >
              {{ selectedLeaderboard == false ? roobetTimer : chickenggTimer }}
              {{ loading == true ? "Calculating time" : "" }}
            </div>
            <div class="hidden md:block">
              <label
                class="toggle-switch mt-8"
                style="font-family: Stalinist One, sans-serif"
              >
                <input
                  type="checkbox"
                  @change="changeLeaderboard()"
                  v-model="selectedLeaderboard"
                />
                <div class="slider flex h-full relative">
                  <img
                    class="object-cover h-auto w-[200px] absolute -top-1"
                    :class="selectedLeaderboard == false ? 'block' : 'hidden'"
                    src="../assets/roobet-switch.png"
                  />
                  <img
                    class="object-cover h-auto w-[240px] absolute -top-1"
                    :class="selectedLeaderboard == true ? 'block' : 'hidden'"
                    src="../assets/packdraw-switch.png"
                  />
                </div>
                <span class="left-label">ROOBET</span>
                <span class="right-label">CHICKEN.GG</span>
              </label>
            </div>
            <div class="block md:hidden">
              <ul
                class="flex flex-wrap gap-2 text-xs font-medium text-center text-gray-500 mt-4"
                style="font-family: Stalinist One, sans-serif"
              >
                <li
                  @click="
                    selectedLeaderboard = false;
                    changeLeaderboard();
                  "
                  :class="
                    selectedLeaderboard == false
                      ? 'bg-gradient-to-r from-[#050505_8%] via-[#5C2027_19%] to-[#370040]'
                      : ''
                  "
                  class="rounded-2xl border border-white cursor-pointer"
                >
                  <div class="inline-block px-4 py-2 text-white">Roobet</div>
                </li>
                <li
                  @click="
                    selectedLeaderboard = true;
                    changeLeaderboard();
                  "
                  :class="
                    selectedLeaderboard == true
                      ? 'bg-gradient-to-r from-[#050505_8%] via-[#5C2027_19%] to-[#95006B]'
                      : ''
                  "
                  class="rounded-2xl border border-white cursor-pointer"
                >
                  <div class="inline-block px-4 py-2 text-white">
                    Chicken.GG
                  </div>
                </li>
              </ul>
            </div>
            <div
              v-if="loading == true"
              class="text-center text-white text-2xl md:text-4xl mt-10 font-bold"
              style="font-family: Russo One, sans-serif"
            >
              Loading Leaderboard...
            </div>
            <!-- <div
              v-if="loading == false && selectedLeaderboard"
              class="text-center text-white text-2xl md:text-4xl mt-10 font-bold"
              style="font-family: Russo One, sans-serif"
            >
              UPDATING SOON...
            </div> -->
          </div>
          <div
            v-if="loading == false"
            class="hidden md:block"
          >
            <div
              class="flex justify-center mt-8 mb:mb-24"
              style="font-family: Russo One, sans-serif"
            >
              <table
                class="w-full lg:w-auto border-separate border-spacing-y-6"
              >
                <thead
                  v-motion-slide-visible-bottom
                  class="rounded-2xl font-['Stalinist_One']"
                  :class="selectedLeaderboard == true ? 'bg-black' : 'bg-black'"
                >
                  <tr>
                    <th
                      class="px-1 md:px-6 lg:pr-20 lg:pl-8 py-2 md:py-4 text-transparent text-lg xl:text-[25px] font-normal xl:font-semibold text-left rounded-l-full bg-clip-text bg-gradient-to-r from-[#FF0000] via-[#FF7373_50%] to-[#FF0000]"
                    >
                      Ranks
                    </th>
                    <th
                      class="px-1 md:px-10 lg:px-16 py-2 md:py-4 text-transparent text-lg xl:text-[25px] font-normal xl:font-semibold text-center bg-clip-text bg-gradient-to-r from-[#FF0000] via-[#FF7373_50%] to-[#FF0000]"
                    >
                      User
                    </th>
                    <th
                      class="px-1 md:px-10 lg:px-16 py-2 md:py-4 text-transparent text-lg xl:text-[25px] font-normal xl:font-semibold text-center bg-clip-text bg-gradient-to-r from-[#FF0000] via-[#FF7373_50%] to-[#FF0000]"
                    >
                      Amount
                    </th>
                    <th
                      class="px-1 md:px-8 lg:pl-16 lg:pr-8 py-2 md:py-4 text-transparent text-lg xl:text-[25px] font-normal xl:font-semibold text-right rounded-r-full bg-clip-text bg-gradient-to-r from-[#FF0000] via-[#FF7373_50%] to-[#FF0000]"
                    >
                      Prize
                    </th>
                  </tr>
                </thead>
                <tbody class="mt-4">
                  <tr
                    v-motion-slide-visible-bottom
                    v-for="(item, index) in top3"
                    :key="index"
                    :class="
                      selectedLeaderboard == true
                        ? 'bg-gradient-to-r from-[#9B005D] via-[#350003] to-[#57003F]'
                        : 'bg-gradient-to-r from-[#840000] via-[#350003] to-[#57003F]'
                    "
                  >
                    <td
                      class="px-1 md:px-[45px] lg:px-10 py-2 md:py-4 whitespace-nowrap text-[18px] font-semibold text-left"
                    >
                      <div class="flex flex-row items-center gap-2">
                        <div class="text-white">
                          {{ getOrdinalSuffix(index + 1) }}
                        </div>
                        <!-- <img src="../assets/logo.png" class="w-[40px]" alt="logo"> -->
                      </div>
                    </td>
                    <td
                      class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 whitespace-nowrap text-[18px] text-white font-thin text-center"
                    >
                      {{ maskString(!selectedLeaderboard ? item.username : item?.displayName) }}
                    </td>
                    <td
                      class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 whitespace-nowrap text-[18px] text-white font-thin text-center"
                    >
                      ${{
                        selectedLeaderboard == false
                          ? item?.weightedWagered?.toFixed(2)
                          : item?.wagerAmount?.toFixed(2)
                      }}
                    </td>
                    <td
                      class="px-1 md:px-[45px] lg:px-8 py-2 md:py-4 whitespace-nowrap text-[18px] text-white font-thin text-right"
                    >
                      ${{
                        selectedLeaderboard == false
                          ? getRoobetRewardValue(index)
                          : getChickenggRewardValue(index)
                      }}
                    </td>
                  </tr>
                </tbody>
                <tbody class="mt-8">
                  <tr
                    v-motion-slide-visible-bottom
                    v-for="(item, index) in topRest"
                    :key="index"
                    class="bg-transparent"
                  >
                    <td
                      class="px-1 md:px-[45px] lg:px-10 py-2 md:py-4 whitespace-nowrap text-[18px] font-semibold text-left"
                    >
                      <div class="flex flex-row items-center gap-2">
                        <div class="text-white">
                          {{ getOrdinalSuffix(index + 4) }}
                        </div>
                        <!--   <img src="../assets/logo.png" class="w-[40px]" alt="logo"> -->
                      </div>
                    </td>
                    <td
                      class="px-1 md:px-[45px] lg:px-24 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-thin text-center"
                    >
                      {{ maskString(!selectedLeaderboard ? item.username : item?.displayName) }}
                    </td>
                    <td
                      class="px-1 md:px-[45px] lg:px-24 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-thin text-center"
                    >
                      ${{
                        selectedLeaderboard == false
                          ? item?.weightedWagered?.toFixed(2)
                          : item?.wagerAmount?.toFixed(2)
                      }}
                    </td>
                    <td
                      class="px-1 md:px-[45px] lg:px-8 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-thin text-right"
                    >
                      ${{
                        selectedLeaderboard == false
                          ? getRoobetRewardValue(index + 3)
                          : getChickenggRewardValue(index + 3)
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            v-if="loading == false"
            class="block md:hidden"
          >
            <div
              class="flex justify-center mt-8 mb:mb-24 text-xl"
              style="font-family: Russo One, sans-serif"
            >
              <table class="w-full lg:w-auto">
                <thead v-motion-slide-visible-bottom>
                  <tr>
                    <th
                      class="md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-left"
                    >
                      Ranks
                    </th>
                    <th
                      class="md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-center"
                    >
                      User
                    </th>
                    <th
                      class="md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-center"
                    >
                      Amount
                    </th>
                    <th
                      class="md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-right"
                    >
                      Prize
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-motion-slide-visible-bottom
                    v-for="(item, index) in topForMobile"
                    :key="index"
                  >
                    <td
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-left"
                    >
                      {{ getOrdinalSuffix(index + 1) }}
                    </td>
                    <td
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-center"
                    >
                      {{ maskString(!selectedLeaderboard ? item.username : item?.displayName) }}
                    </td>
                    <td
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-center"
                    >
                      ${{
                        selectedLeaderboard == false
                          ? item?.weightedWagered?.toFixed(2)
                          : item?.wagerAmount?.toFixed(2)
                      }}
                    </td>
                    <td
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-right"
                    >
                      ${{
                        selectedLeaderboard == false
                          ? getRoobetRewardValue(index)
                          : getChickenggRewardValue(index)
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            v-if="loading == false && selectedLeaderboard == false"
            v-motion-pop-visible
            class="flex justify-center text-white mt-8 lg:mt-14 uppercase font-semibold tracking-wider px-2 md:px-4 lg:px-48 2xl:px-96 2xl:ml-4 bg-gradient-to-r from-[#020202] via-[#200000_50%] to-[#020202] msm:m-[20px] msm:p-[1rem]"
            style="font-family: Roboto, serif"
          >
            Your wagers on Roobet will count towards the leaderboard at the
            following weights based on the games you are playing. This helps
            prevent leaderboard abuse:

            <br /><br />*Games with an RTP under 96% will contribute 100% of the
            amount wagered to the leaderboard. <br />*Games with an RTP of 97%
            will contribute 50% of the amount wagered to the leaderboard.
            <br />*Games with an RTP of 98- 99% and above will contribute 10% of
            the amount wagered to the leaderboard. <br />*Only house games and
            slots count towards leaderboard.
          </div>
          <!-- <div
            v-if="loading == false && selectedLeaderboard"
            v-motion-pop-visible
            class="mt-4 flex justify-center text-center text-xl text-white px-2 lg:mt-5 shadow-xl uppercase font-base tracking-wider"
            style="font-family: Roboto, serif"
          >
            *All wagers for Chicken.GG from 11/20/2024 will be
            counted until Chicken.GG Leaderboard is integrated
          </div> -->
        </div>
      </div>
    </section>
    <PrevLeaderBoardSection ref="PrevLeaderBoardSection" />
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment-timezone";
import PrevLeaderBoardSection from "./PrevLeaderBoardSection.vue";
import { CHICKENGG_PRIZES_CURRENT, ROOBET_PRIZES_CURRENT } from "../../const";

export default {
  components: {
    PrevLeaderBoardSection,
  },
  data() {
    return {
      top3: [],
      topRest: [],
      topForMobile: [],

      roobetTimer: "",
      packdrawTimer: "",
      chickenggTimer: '',

      rooberLeaderboard: [],
      packdrawLeaderboard: [],
      chickenggLeaderboard: [],

      selectedLeaderboard: false,

      roobetStartDate: null,
      roobetEndDate: null,

      packdrawStartDate: null,
      packdrawEndDate: null,

      roobetNotFormattedEndDate: null,
      packdrawNotFormattedEndDate: null,
      chickenggNotFormattedEndDate: null,

      loading: false,
    };
  },
  methods: {
    async init() {
      const baseUrl = process.env.VUE_APP_BASE_API;
      this.loading = true;
      await axios
        .get(`${baseUrl}/api/leaderboard/roobet`, {
          headers: {
            "x-api-key": process.env.VUE_APP_X_API_KEY,
          },
        })
        .then((res) => {
          this.rooberLeaderboard = res.data.leaderboard;
          this.roobetNotFormattedEndDate = res.data.endDate;
        });

      await axios
        .get(`${baseUrl}/api/leaderboard/chickengg`, {
          headers: { "x-api-key": process.env.VUE_APP_X_API_KEY },
        })
        .then((res) => {
          this.chickenggLeaderboard = res.data.leaderboard;
          this.chickenggNotFormattedEndDate = res.data.endDate;
        })
        .catch((e) => console.error(e));

      if (this.loading == true) {
        this.changeLeaderboard();
        this.startRoobetTimer();
        this.startChickenggTimer();
        this.loading = false;
      }
    },
    changeLeaderboard() {
      if (this.selectedLeaderboard == false) {
        const leaderboard = this.rooberLeaderboard;
        this.top3 = leaderboard.slice(0, 3).map((item) => {
          return item;
        });
        //slice returns no value
        this.topRest = leaderboard.slice(3, 8).map((item) => {
          return item;
        });
        // slice return top 3 only
        this.topForMobile = leaderboard.slice(0, 8).map((item) => {
          return item;
        });
      } else {
        const leaderboard = this.chickenggLeaderboard;
        this.top3 = leaderboard.slice(0, 3).map((item) => {
          return item;
        });

        this.topRest = leaderboard.slice(3, 8).map((item) => {
          return item;
        });

        this.topForMobile = leaderboard.slice(0, 8).map((item) => {
          return item;
        });
      }
    },
    getOrdinalSuffix(n) {
      const s = ["th", "st", "nd", "rd"],
        v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },
    getRoobetRewardValue(rank) {
      if (rank >= 11) {
        return 25;
      }
      return ROOBET_PRIZES_CURRENT[rank];
    },
    getChickenggRewardValue(rank) {
      if (rank >= 4) {
        return 25;
      }
      return CHICKENGG_PRIZES_CURRENT[rank];
    },
    startRoobetTimer() {
      const getNextTargetDate = () => {
        // const now = moment.tz("America/New_York");
        const now = moment().utc();
        // let targetDate = moment
        //   .tz(this.roobetNotFormattedEndDate, "America/New_York")
        //   .hour(23)
        //   .minute(59)
        //   .second(59)
        //   .subtract(1, "days");
        let targetDate = moment(this.roobetNotFormattedEndDate).utc();

        if (now.isSameOrAfter(targetDate)) {
          targetDate.add(2, "week");
        }

        return targetDate;
      };

      let targetDate = getNextTargetDate();
      let roobetTimerInterval;

      const updateTimer = () => {
        // const nowEst = moment.tz("America/New_York");
        const nowEst = moment().utc();
        const distance = targetDate.diff(nowEst);

        if (distance < 0) {
          this.timer = "Leaderboard ended";
          this.init();
          this.$refs.PrevLeaderBoardSection.init();
          clearInterval(roobetTimerInterval);

          targetDate = getNextTargetDate();
          this.startRoobetTimer();
        } else {
          const duration = moment.duration(distance);
          const days = Math.floor(duration.asDays());
          const hours = duration.hours();
          const minutes = duration.minutes();
          const seconds = duration.seconds();

          this.roobetTimer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        }
      };
      updateTimer();
      roobetTimerInterval = setInterval(updateTimer, 1000);
    },
    startChickenggTimer() {
      const getNextTargetDate = () => {
        // const now = moment.utc();

        let targetDate = moment
          .utc(this.chickenggNotFormattedEndDate)
          .subtract(1, "seconds");

        // if (now.isAfter(targetDate)) {
        //   targetDate.add(1, 'month');
        // }

        return targetDate;
      };

      let targetDate = getNextTargetDate();
      let timerInterval;

      const updateTimer = () => {
        const nowUtc = moment.utc();
        const distance = targetDate.diff(nowUtc);

        if (distance < 0) {
          this.timer = "Leaderboard ended";
          // this.init();
          clearInterval(timerInterval);

          // targetDate = getNextTargetDate();
          // this.startTimer();
        } else {
          const duration = moment.duration(distance);
           const days = Math.floor(duration.asDays());
           const hours = duration.hours();
           const minutes = duration.minutes();
           const seconds = duration.seconds();

          this.chickenggTimer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        }
      };

      updateTimer();
      timerInterval = setInterval(updateTimer, 1000);
    },
    maskString(str) {
      if (!str || str.length < 2) {
        return str;
      }
      if (!str || str.length <= 4) {
        const start = str.slice(0, 1);
        const end = str.slice(-1);
        const middleAsterisks = "*".repeat(str.length - 2);

        return `${start}${middleAsterisks}${end}`;
      } else {
        const start = str.slice(0, 2);
        const end = str.slice(-2);
        const middleAsterisks = "*".repeat(str.length - 4);

        return `${start}${middleAsterisks}${end}`;
      }
    },
    formatDateNow() {
      return moment().utc().format("MM/DD/YYYY");
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style scoped>
.leaderboard-bg {
  background-image: url("../assets/leaderboard-section-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.toggle-switch {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 420px;
  height: 56px;
  background: linear-gradient(to right, #140000, #440000 34%);
  border-radius: 28px;
  padding: 0 8px;
  cursor: pointer;
}

.toggle-switch input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch .slider {
  /* position: absolute;
  left: 4px; */
  width: 240px;
  height: 48px;
  /* background-color: rgba(255, 255, 255, 0.4); */
  /* border-radius: 24px; */
  transition: transform 0.3s ease;
}

.toggle-switch input:checked + .slider {
  transform: translateX(180px);
}

.toggle-switch span {
  color: white;
  font-size: 15px;
  font-weight: bold;
  z-index: 1;
  padding: 0 8px;
}

.toggle-switch .left-label {
  position: absolute;
  top: 16px;
  left: 32px;
}

.toggle-switch .right-label {
  position: absolute;
  top: 18px;
  right: 30px;
}
</style>
